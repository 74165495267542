<template>
  <div class="app-container">
    <van-cell-group title="个人信息">
      <van-field
        v-model="memo"
        rows="2"
        autosize
        label="个人简介"
        type="textarea"
        maxlength="50"
        placeholder="请输入"
      />
    </van-cell-group>

    <van-cell-group title="我的图片">
      <div class="upload-wrapper">
        <van-uploader
          name="card"
          :accept="'image/*'"
          :after-read="onRead"
          v-model="fileList"
          :max-count="9"
        />
      </div>
    </van-cell-group>
    <div class="btn">
      <van-button type="info" block @click="onSubmit">确定修改</van-button>
    </div>

    <!-- <pre>
      {{ fileList }}
    </pre> -->
  </div>
</template>
<script>
import { Notify } from 'vant'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      wechat_avatar: Cookies.get('wechat_avatar'),
      wechat_nickname: Cookies.get('wechat_nickname'),
      dataList: {},
      memo: '',
      fileList: []
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    getDataList () {
      this.$axios.post('/wxc/index/card_page', {
        seller_id: Number(Cookies.get('client_id'))
      }).then(res => {
        this.dataList = res.data
        this.memo = res.data.client_card_cfg.memo
        res.data.client_card_cfg.cover_img.split('|').map(item => {
          this.fileList.push({
            url: item
          })
        })
      })
    },
    onRead (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      let formData = new FormData()
      formData.append('type', 'card')
      formData.append('file', file.file)
      this.$axios.post('/wxc/index/upload', formData).then(res => {
        if (res.code === 200) {
          file.url = res.data.file_url
          file.status = 'done'
          file.message = '上传成功'
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      })
    },
    onSubmit () {
      let form = {
        memo: this.memo,
        cover_img: []
      }
      this.fileList.map(item => {
        form.cover_img.push(item.url)
      })
      this.$axios.post('/wxc/client/card_edit', form).then(res => {
        if (res.code === 200) {
          Notify({ type: 'success', message: res.msg });
        }
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.upload-wrapper {
  padding: 8px 0 0 8px;
}
.btn {
  padding: 20px;
}
</style>